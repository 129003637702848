@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300&display=swap");
@import "~bootstrap/dist/css/bootstrap.min.css";

body {
  padding: 0;
  margin: 0;
}

* {
  font-family: "Noto Sans JP";
}
